<template>
    <div class="order-section">
        <IndividualOrderKitCard
            class="card"
            :order="checkOut.order"
            :checkOut="checkOut"
            :kit-order-settings="kitOrderSettings"
            @openConfirmationModal="openConfirmationModal"
            @updateIndividualOrder="updateIndividualOrder"
            @openIndividualOrderModal="isIndividualOrderModalOpened = true"
            @reorderIndividualOrder="reorderIndividualOrder"
            @openChangeOrderModal="isChangeOrderModalOpen = true"
        >
            <template #enter-price-section>
                <div class="order-request-section">
                    <CRInput
                        v-model="price"
                        :placeholder="$t('placeholder.enterOrderPrice')"
                        :label="$t('common.orderPrice')"
                        required
                        type="number"
                        :errors="errors?.price"
                        @onChange="$filters.clearError(errors, 'price')"
                    />
                    <CRButton @click="openConfirmationModal(CheckOutStatusEnum.ORDER_APPROVED)" class="btn save-btn">
                        {{ $t('buttons.savePriceApproveOrder') }}
                    </CRButton>
                    <CRButton @click="openConfirmationModal(CheckOutStatusEnum.DECLINED)" class="btn" color="danger">
                        {{ $t('buttons.cantBeProcessed') }}
                    </CRButton>
                </div>
            </template>

            <template #comment-section>
                <h4 class="comment-title">Comments</h4>
                <div v-if="isCommentEditMode">
                    <CRTextarea
                        class="textarea"
                        v-model="comment"
                        :label="$t('common.orderedKitComments')"
                        :placeholder="$t('placeholder.enterOrderedKitComments')"
                        :max-length="500"
                        :errors="errors?.comment"
                        @onChange="$filters.clearError(errors, 'comment')"
                    />
                    <CRButton @click="updateComment">
                        {{ $t('buttons.saveComments') }}
                    </CRButton>
                </div>
                <div v-else>
                    <CRTag
                        class="comment-tag"
                        type="additional"
                        delete-allowed
                        svg-color="#00595A"
                        @delete="deleteComment"
                    >
                        {{ checkOut.comment }}
                    </CRTag>
                    <CRButton @click="isCommentEditMode = true" pattern="thin">
                        {{ $t('buttons.change') }}
                    </CRButton>
                </div>
            </template>
        </IndividualOrderKitCard>

        <IndividualOrderModal
            v-if="isIndividualOrderModalOpened"
            :checkOut="checkOut"
            :order="checkOut.order"
            @close="isIndividualOrderModalOpened = false"
        />

        <ChangeOrderModal
            v-if="isChangeOrderModalOpen"
            :kit-order-settings="kitOrderSettings"
            :order="checkOut.order"
            :checkOut="checkOut"
            @close="closeChangeOrderModal"
        />

        <ConfirmationModal
            v-if="isConfirmationModalOpen"
            :title="confirmationModalTitle"
            :is-loading="isLoading"
            @close="closeConfirmationModal"
            @confirm="updateStatusWithPrice"
        />

        <ConfirmationModal
            v-if="isConfirmationOrderModalOpen"
            :title="confirmationOrderModalTitle"
            :is-loading="isLoading"
            @close="closeOrderConfirmationModal"
            @confirm="confirmActionFromConfirmationModal"
        />
    </div>
</template>

<script>
    import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue';
    import IndividualOrderModal from '@/components/Modals/IndividualOrderModal.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import ChangeOrderModal from '@/components/Modals/ChangeOrderModal.vue';
    import IndividualOrderKitCard from '@/components/Common/KitOrderCards/IndividualOrderKitCard.vue';
    import { orderManagementSectionsMixin, kitManagementModalMixin, reorderIOMixin } from '@/utils/mixins';
    import { CheckOutStatusEnum } from '@/utils/enums';
    import { IndividualOrderApi } from '@/api';
    import { mapActions } from 'vuex';
    import { debounce } from 'lodash';

    export default {
        name: 'IndividualOrderSection',
        components: {
            CRInput,
            CRButton,
            CRTextarea,
            CRTag,
            IndividualOrderModal,
            ConfirmationModal,
            IndividualOrderKitCard,
            ChangeOrderModal,
        },
        mixins: [orderManagementSectionsMixin, kitManagementModalMixin, reorderIOMixin],
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
            kitOrderSettings: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                CheckOutStatusEnum,

                price: '',
                comment: this.checkOut.comment,
                errors: {},

                selectedOrder: null,

                isCommentEditMode: true,
                isIndividualOrderModalOpened: false,

                isChangeOrderModalOpen: false,
            };
        },
        watch: {
            'checkOut.comment': {
                handler(data) {
                    if (data) {
                        this.isCommentEditMode = false;
                    }
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions('checkOut', ['setCheckOut']),
            ...mapActions('orderManagement', ['getCheckOut']),

            updateIndividualOrder({ status, order }) {
                this.openOrderConfirmationModal(status);
                this.selectedOrder = order;
            },

            async closeChangeOrderModal() {
                try {
                    this.selectedOrder = null;
                    this.isChangeOrderModalOpen = false;

                    await this.getCheckOut(this.checkOut.id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async confirmActionFromConfirmationModal() {
                this.isLoading = true;

                try {
                    await IndividualOrderApi.update(this.selectedOrder.id, {
                        status: this.orderStatus,
                    });

                    await this.getCheckOut(this.checkOut.id);
                } catch (error) {
                    console.log(error);
                } finally {
                    this.closeOrderConfirmationModal();
                    this.isLoading = false;
                    this.selectedOrder = null;
                }
            },

            async updateStatusWithPrice() {
                this.isLoading = true;

                try {
                    const payload = {
                        id: this.checkOut.id,
                        data: { status: this.status },
                    };

                    if (this.status === CheckOutStatusEnum.ORDER_APPROVED) {
                        payload.data.price = this.price;
                    }

                    await this.updateCheckOut(payload);
                } catch (error) {
                    this.errors = error.errors;
                } finally {
                    this.closeConfirmationModal();
                    this.isLoading = false;
                }
            },

            async deleteComment() {
                this.comment = '';
                await this.updateComment();
            },

            updateComment: debounce(async function () {
                try {
                    const payload = {
                        id: this.checkOut.id,
                        data: {
                            comment: this.comment,
                        },
                    };

                    await this.updateCheckOut(payload);

                    if (this.comment) {
                        this.isCommentEditMode = false;
                    } else {
                        this.isCommentEditMode = true;
                    }
                } catch (error) {
                    this.errors = error.errors;
                }
            }, 500),
        },
    };
</script>

<style lang="scss" scoped>
    .order-section {
        margin-bottom: 20px;

        .comment-title {
            margin-bottom: 12px;
        }

        .comment-tag {
            @include row-align-center-justify-between;

            margin-bottom: 12px;

            :deep(.tag-container__text) {
                max-width: 90%;

                font-size: $font-16;
                font-weight: 400;
                text-align: start;
                color: $black;
            }
        }

        .order-request-section {
            margin-top: 25px;

            .btn {
                width: 100%;
            }

            .save-btn {
                margin: 12px 0;
            }
        }

        .textarea {
            margin: 12px 0;
        }
    }

    @include media($md) {
        .order-section {
            .card {
                width: 49%;
            }
        }
    }

    @include media($xl) {
        .order-section {
            .card {
                width: 32%;
            }
        }
    }
</style>

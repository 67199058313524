<template>
    <BasicModal class="basic-modal" @close="$emit('close')">
        <div class="container">
            <h2 class="mb-8">{{ $t('buttons.changeDetails') }}</h2>
            <h5>
                {{
                    $t('kitManagement.allChangesApartFromContactsWouldBeDoneAfter', {
                        hours: kitOrderSettings.changesAppliedTermHours,
                    })
                }}
            </h5>

            <div class="order-delivery-item">
                <h4 class="mb-18">{{ $t('common.deliveryMethod') }}</h4>
                <CRRadio
                    class="radio"
                    v-for="method in deliveryMethods"
                    v-model="deliveryMethodId"
                    :key="method.id"
                    :value="method.id"
                >
                    {{ $t(`deliveryMethods.${method.type}`) }}
                </CRRadio>
            </div>

            <div class="buyer-information-item mb-16">
                <h4>{{ $t('buyerInfo.deliveryAddress') }}</h4>
                <div class="btn__wrapper" v-if="user?.addresses?.length">
                    <CRTag
                        class="btn"
                        v-for="address in user.addresses"
                        :key="address.id"
                        @click="selectAddress(address)"
                        icon="location"
                        :type="selectedAddress?.id === address.id ? 'primary' : 'additional'"
                        edit-allowed
                        :svgColor="selectedAddress?.id === address.id ? '#fff' : '#00595A'"
                        @edit="openEditModal(address)"
                    >
                        {{ address.name }}
                    </CRTag>
                </div>

                <AddressForm
                    @onFormChange="onAddressFormChange"
                    :address-errors="addressErrors"
                    :address="selectedAddress"
                >
                    <template #footer="{ address }">
                        <div v-if="user" class="description">
                            {{ $t('buyerInfo.youCanSaveAddress') }} -
                            <CRButton class="btn" pattern="thin" @click="saveAddress(address)">
                                {{ $t('buttons.save') }}
                            </CRButton>
                        </div>
                    </template>
                </AddressForm>
            </div>

            <div class="buyer-information-item">
                <h4>{{ $t('buyerInfo.contacts') }}</h4>
                <CRInput
                    v-model="v$.userData.phone.$model"
                    class="input"
                    required
                    :label="$t('common.phoneNr')"
                    :placeholder="$t('placeholder.enterPhoneNumber')"
                    :errors="v$.userData.phone.$errors"
                    is-vuelidate
                />
                <CRInput
                    v-model="v$.userData.email.$model"
                    class="input mb-16"
                    required
                    :label="$t('common.email')"
                    :placeholder="$t('placeholder.enterEmail')"
                    :errors="v$.userData.email.$errors"
                    is-vuelidate
                />
            </div>

            <CRButton @click="changeCheckOut" :loading="isLoading">
                {{ $t('buttons.change') }}
            </CRButton>
        </div>

        <EditAddressModal
            v-if="isEditAddressModalOpen"
            @close="closeEditModal"
            @destroyed="closeEditModalOnDestroy"
            @updated="closeEditModalOnUpdate"
            :address="editedAddress"
        />
    </BasicModal>
</template>

<script>
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import EditAddressModal from '@/components/Modals/EditAddressModal.vue';
    import AddressForm from '@/components/Forms/AddressForm.vue';
    import { DeliveryMethodApi, CheckOutApi } from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import { INITIAL_ADDRESS_DATA } from '@/components/Forms/AddressForm.vue';
    import { cloneDeep } from 'lodash';
    import { useVuelidate } from '@vuelidate/core';
    import { minLengthRule, phoneRule, requiredRule, emailRule } from '@/helpers/VuelidateRuleHelper';

    export default {
        name: 'ChangeCheckOutModal',
        components: { BasicModal, CRRadio, CRButton, CRTag, EditAddressModal, AddressForm, CRInput },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
            kitOrderSettings: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['close'],
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                deliveryMethods: [],
                deliveryMethodId: 0,

                addressData: { ...INITIAL_ADDRESS_DATA },
                // edit address
                selectedAddress: null,
                isEditAddressModalOpen: false,
                editedAddress: null,
                userData: {
                    phone: '',
                    email: '',
                },

                isLoading: false,

                addressErrors: {},
            };
        },
        validations() {
            return {
                userData: {
                    phone: {
                        required: requiredRule(),
                        minLength: minLengthRule(9),
                        phone: phoneRule(),
                    },
                    email: {
                        required: requiredRule(),
                        email: emailRule(),
                    },
                },
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('city', ['selectedCity']),
        },
        methods: {
            ...mapActions('auth', ['storeAddress']),

            async changeCheckOut() {
                this.isLoading = true;

                try {
                    const result = await this.v$.$validate();
                    if (!result) {
                        this.$filters.toast(this.$t('errors.someFieldsAreNotFilledCorrectly'));
                        return;
                    }

                    let addressCopy = { ...this.addressData };
                    addressCopy.cityId = this.addressData.city.id;
                    delete addressCopy.city;

                    const form = {
                        deliveryMethodId: this.deliveryMethodId,
                        userData: this.userData,
                        deliveryAddress: addressCopy,
                    };

                    await CheckOutApi.update(this.checkOut.id, form);
                    this.$emit('close');
                } catch (error) {
                    Object.entries(error.errors).forEach(async (element) => {
                        this.$filters.toast(element[1][0]);
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            setDataIfExistInState() {
                this.selectedAddress = { ...this.checkOut.deliveryAddress };
                this.deliveryMethodId = this.checkOut.deliveryInformation.deliveryMethod.id;

                this.userData.phone = this.checkOut.deliveryUser.phone;
                this.userData.email = this.checkOut.deliveryUser.email;
            },

            async getDeliveryMethods() {
                try {
                    const response = await DeliveryMethodApi.getAll({ isActive: true });
                    this.deliveryMethods = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            onAddressFormChange(data) {
                this.addressData = data;
            },

            selectAddress(address) {
                this.selectedAddress = address;
                this.addressData = address;
            },

            // edit address
            openEditModal(address) {
                this.editedAddress = address;
                this.isEditAddressModalOpen = true;
            },

            closeEditModalOnDestroy() {
                this.addressData = { ...INITIAL_ADDRESS_DATA };
                this.addressData.city = this.selectedCity;

                this.selectedAddress = { ...INITIAL_ADDRESS_DATA };
                this.selectedAddress.city = this.selectedCity;
            },

            closeEditModalOnUpdate() {
                if (this.selectedAddress?.id === this.editedAddress.id) {
                    const address = this.user.addresses.find((address) => address.id === this.editedAddress.id);
                    this.selectedAddress = address;
                    this.addressData = address;
                }
            },

            closeEditModal() {
                this.editedAddress = null;
                this.isEditAddressModalOpen = false;
            },

            // save address
            async saveAddress(address) {
                try {
                    let addressCopy = cloneDeep(address);

                    addressCopy.name = `${this.$t('common.address')} ${this.user.addresses.length + 1}`;
                    addressCopy.cityId = addressCopy.city.id;

                    delete addressCopy.city;

                    await this.storeAddress(addressCopy);

                    this.selectedAddress = this.user.addresses.slice(-1).pop();
                    this.addressData = this.user.addresses.slice(-1).pop();
                } catch (error) {
                    this.addressErrors = error.errors;
                }
            },
        },
        async mounted() {
            await this.getDeliveryMethods();

            this.setDataIfExistInState();
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start;

        width: 100%;

        padding: 20px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        h5 {
            color: $grey-static;
            margin-bottom: 24px;
        }

        .order-delivery-item {
            margin-bottom: 24px;

            .radio {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .buyer-information-item {
            width: 100%;

            h4 {
                margin-bottom: 24px;
            }

            .input {
                margin-bottom: 24px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .btn__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                margin-bottom: 18px;

                .btn {
                    cursor: pointer;
                    margin-bottom: 10px;

                    margin-right: 8px;
                }

                .btn:last-child {
                    margin-right: 0;
                }
            }

            .description {
                margin-top: 16px;

                .btn {
                    display: inline;
                }
            }
        }
    }
</style>

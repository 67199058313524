<template>
    <div class="delivery-info-section">
        <h3>{{ $t('order.deliveryInformation') }}</h3>
        <CheckOutCard class="delivery" :slots-count="5">
            <template #inner-item-1>
                <div class="wrapper">
                    <h3>{{ $t('common.delivery') }}</h3>
                    <OMTitleSection
                        class="f-column-align-start mr-20"
                        :title="$t('common.name')"
                        :text="deliveryUser.firstName"
                    />
                    <OMTitleSection
                        class="f-column-align-start"
                        :title="$t('common.surname')"
                        :text="deliveryUser.lastName"
                    />
                </div>
            </template>
            <template #inner-item-2>
                <div class="wrapper">
                    <OMTitleSection class="f-column-align-start mr-20" :title="$t('common.phoneNr')">
                        <a :href="`tel:${deliveryUser.phone}`">{{ deliveryUser.phone }}</a>
                    </OMTitleSection>
                    <OMTitleSection class="f-column-align-start" :title="$t('common.email')">
                        <a :href="`mailto:${deliveryUser.email}`">{{ deliveryUser.email }}</a>
                    </OMTitleSection>
                </div>
            </template>
            <template #inner-item-3>
                <OMTitleSection
                    class="f-column-align-start"
                    :title="$t('common.deliveryMethod')"
                    :text="$t(`deliveryMethods.${deliveryInformation.deliveryMethod.type}`)"
                />
                <div class="f-align-center">
                    <CRTag v-if="deliveryInformation.updateRequest" type="special" class="mt-4">
                        {{ $t(`deliveryMethods.${deliveryInformation.updateRequest.data.deliveryMethod.type}`) }},
                        {{ deliveryInformation.updateRequest.changesAppliedDate }}
                    </CRTag>
                </div>
            </template>
            <template #inner-item-4>
                <OMTitleSection class="f-column-align-start mr-20" :title="$t('common.address')">
                    <h6>{{ deliveryAddress.city.name }}</h6>
                    <h6>
                        {{ deliveryAddress.street }}, {{ deliveryAddress.houseNumber
                        }}<template v-if="deliveryAddress.postalCode">, {{ deliveryAddress.postalCode }} </template>
                    </h6>
                    <h6 v-if="deliveryAddress.apartmentNumber">
                        {{ $t('common.apartmentNumber') }}: {{ deliveryAddress.apartmentNumber }}
                    </h6>
                    <h6 v-if="deliveryAddress.floor">{{ $t('common.floor') }}: {{ deliveryAddress.floor }}</h6>
                    <h6 v-if="deliveryUser.companyName">{{ $t('user.company') }}: {{ deliveryUser.companyName }}</h6>
                    <h6 v-if="deliveryAddress.extraInformation">
                        {{ deliveryAddress.extraInformation }}
                    </h6>
                    <div class="f-align-center">
                        <CRTag v-if="deliveryAddress.updateRequest" type="special" class="mt-4">
                            {{
                                displayDeliveryAddressUpdateRequest(deliveryAddress.updateRequest.data.deliveryAddress)
                            }},
                            {{ deliveryAddress.updateRequest.changesAppliedDate }}
                        </CRTag>
                    </div>
                </OMTitleSection>
            </template>
            <template #inner-item-5>
                <OMTitleSection
                    class="f-column-align-start mr-20"
                    :title="$t('deliveryInfo.informationToCourier')"
                    :text="deliveryInformation.extraInformation ?? '-'"
                />
            </template>
        </CheckOutCard>
    </div>
</template>

<script>
    import CheckOutCard from '@/components/OrderManagement/CheckOutCard.vue';
    import OMTitleSection from '@/components/OrderManagement/OMTitleSection.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';

    export default {
        name: 'DeliveryInfoSection',
        components: { CheckOutCard, OMTitleSection, CRTag },
        props: {
            deliveryUser: {
                type: Object,
                default: () => {},
            },
            deliveryAddress: {
                type: Object,
                default: () => {},
            },
            deliveryInformation: {
                type: Object,
                default: () => {},
            },
        },
        methods: {
            displayDeliveryAddressUpdateRequest(data) {
                const array = [];
                Object.entries(data).forEach((element) => {
                    if (element[1]) {
                        const string = `${this.$t(`common.${element[0]}`)}: ${element[1]}`;
                        array.push(string);
                    }
                });

                return array.join(', ');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .delivery-info-section {
        h3 {
            margin-bottom: 12px;
        }

        .wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            h3 {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    @include media($md) {
        .delivery {
            width: 49%;
        }
    }

    @include media($xl) {
        .delivery {
            width: 32%;
        }
    }
</style>

<template>
    <div class="order-section">
        <CRButton
            v-if="!isAdmin && !kitOrderSettings?.isUserChangesDisabled"
            class="mb-20"
            @click="isChangeCheckOutModalOpened = true"
        >
            {{ $t('buttons.changeDetails') }}
        </CRButton>
        <RegularOrdersSection
            v-if="checkOut.type === CheckOutTypeEnum.REGULAR_ORDER"
            :check-out="checkOut"
            :kit-order-settings="kitOrderSettings"
        />
        <IndividualOrderSection v-else :check-out="checkOut" :kit-order-settings="kitOrderSettings" />
        <ChangeCheckOutModal
            v-if="isChangeCheckOutModalOpened"
            :check-out="checkOut"
            :kit-order-settings="kitOrderSettings"
            @close="closeChangeCheckOutModal"
        />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import RegularOrdersSection from '@/components/OrderManagement/RegularOrdersSection.vue';
    import IndividualOrderSection from '@/components/OrderManagement/IndividualOrderSection.vue';
    import ChangeCheckOutModal from '@/components/Modals/ChangeCheckOutModal.vue';
    import { CheckOutTypeEnum } from '@/utils/enums';
    import { KitOrderSettingApi } from '@/api';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'OrderSection',
        components: { RegularOrdersSection, IndividualOrderSection, CRButton, ChangeCheckOutModal },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                CheckOutTypeEnum,
                kitOrderSettings: null,

                isChangeCheckOutModalOpened: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isAdmin']),
        },
        methods: {
            ...mapActions('orderManagement', ['getCheckOut']),

            async fetchSettings() {
                try {
                    const response = await KitOrderSettingApi.get();
                    this.kitOrderSettings = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async closeChangeCheckOutModal() {
                this.isChangeCheckOutModalOpened = false;
                try {
                    await this.getCheckOut(this.checkOut.id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.fetchSettings();
        },
    };
</script>

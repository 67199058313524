<template>
    <div class="header mb-20">
        <CheckOutCard class="mobile-header" :slots-count="3">
            <template #inner-item-1>
                <div class="f-align-end-justify-between">
                    <!-- Order number -->
                    <OMTitleSection :title="$t('orderManagement.orderNumber')" :text="checkOut.orderNumber" />
                    <!-- Order status -->
                    <OMStatus :status="checkOut.status" :payment-type="checkOut.payment?.paymentableType" />
                </div>
            </template>

            <template #inner-item-2>
                <div class="f-align-center-justify-between">
                    <!-- Order date -->
                    <OMTitleSection :title="$t('orderManagement.orderDate')" :text="checkOut.createdAt" />
                    <!-- Buyers name -->
                    <OMTitleSection
                        v-if="isAdmin"
                        class="f-column-align-end"
                        :title="$t('orderManagement.buyersName')"
                        :text="`${checkOut.deliveryUser.lastName} ${checkOut.deliveryUser.firstName}`"
                    />
                </div>
            </template>

            <template #inner-item-3>
                <div class="f-align-center-justify-between">
                    <!-- Number of kits -->
                    <OMTitleSection :title="$t('orderManagement.numberOfKits')" :text="checkOut.kitsAmount" />

                    <!-- Price and Payment type -->
                    <OMTitleSection class="f-column-align-end">
                        <h2 v-if="checkOut.prices">€{{ checkOut.prices.total_price }}</h2>
                        <p v-if="checkOut.payment">
                            {{ getPaymentTypeName(checkOut.payment.paymentableType) }}
                        </p>
                    </OMTitleSection>
                </div>
            </template>
        </CheckOutCard>

        <div class="desktop-header">
            <!-- Order number -->
            <OMTitleSection :title="$t('orderManagement.orderNumber')" :text="checkOut.orderNumber" />
            <div class="vertical-separator" />

            <!-- Buyers name -->
            <OMTitleSection
                v-if="isAdmin"
                :title="$t('orderManagement.buyersName')"
                :text="`${checkOut.deliveryUser.lastName} ${checkOut.deliveryUser.firstName}`"
            />
            <div v-if="isAdmin" class="vertical-separator" />

            <!-- Order date -->
            <OMTitleSection :title="$t('orderManagement.orderDate')" :text="checkOut.createdAt" />
            <div class="vertical-separator" />

            <!-- Order status -->
            <OMTitleSection :title="$t('orderManagement.orderStatus')">
                <OMStatus class="status" :status="checkOut.status" :payment-type="checkOut.payment?.paymentableType" />
            </OMTitleSection>
            <div class="vertical-separator" />

            <!-- Payment method -->
            <OMTitleSection
                :title="$t('orderManagement.paymentMethod')"
                :text="checkOut.payment ? getPaymentTypeName(checkOut.payment.paymentableType) : '-'"
            />
            <div class="vertical-separator" />

            <!-- Number of kits -->
            <OMTitleSection :title="$t('orderManagement.numberOfKits')" :text="checkOut.kitsAmount" />
            <div class="vertical-separator" />

            <!-- Discount codes-->
            <OMTitleSection :title="$t('orderManagement.discountCode')" :text="''">
                <div v-for="discount in checkOut.discountCodes" :key="discount.id">{{ discount.code }}</div>
            </OMTitleSection>
            <div class="vertical-separator" />

            <!-- Price -->
            <OMTitleSection
                :title="$t('orderManagement.price')"
                :text="checkOut.prices ? `€${checkOut.prices.total_price}` : '-'"
            />
        </div>
    </div>
</template>

<script>
    import OMStatus from '@/components/OrderManagement/OMStatus.vue';
    import OMTitleSection from '@/components/OrderManagement/OMTitleSection.vue';
    import CheckOutCard from '@/components/OrderManagement/CheckOutCard.vue';
    import { getPaymentTypeName } from '@/helpers/PaymentHelper';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CheckOutPageHeader',
        components: { CheckOutCard, OMTitleSection, OMStatus },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                getPaymentTypeName,
            };
        },
        computed: {
            ...mapGetters('auth', ['isAdmin']),
        },
    };
</script>

<style lang="scss" scoped>
    .header {
        .desktop-header {
            display: none;
        }
    }

    @include media($xl) {
        .header {
            .mobile-header {
                display: none;
            }

            .desktop-header {
                @include row-align-center-justify-between;

                padding-bottom: 20px;
                border-bottom: 1px solid $grey-disabled;

                :deep(.om-title-section) {
                    p {
                        margin-bottom: 10px;
                    }
                }

                .status {
                    :deep(p) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>

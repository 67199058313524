<template>
    <div class="check-out-page">
        <div class="title__wrapper">
            <h2 class="mb-10 mr-24">{{ $t('buttons.order') }} - {{ currentCheckOut.orderNumber }}</h2>
            <CRButton class="mb-10" v-if="this.currentCheckOut.pdfLink" @click="download">
                {{ $t('buttons.invoice') }}
            </CRButton>
        </div>
        <CheckOutPageHeader :check-out="currentCheckOut" />
        <OrderSection :check-out="currentCheckOut" />
        <DeliveryInfoSection
            :delivery-user="currentCheckOut.deliveryUser"
            :delivery-address="currentCheckOut.deliveryAddress"
            :delivery-information="currentCheckOut.deliveryInformation"
        />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CheckOutPageHeader from '@/components/OrderManagement/CheckOutPageHeader.vue';
    import OrderSection from '@/components/OrderManagement/OrderSection.vue';
    import DeliveryInfoSection from '@/components/OrderManagement/DeliveryInfoSection.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CheckOutPage',
        components: { CheckOutPageHeader, OrderSection, DeliveryInfoSection, CRButton },
        computed: {
            ...mapGetters('orderManagement', ['currentCheckOut']),
        },
        methods: {
            download() {
                window.open(this.currentCheckOut.pdfLink, '_blank');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .check-out-page {
        padding: 25px 0;

        .title__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-bottom: 10px;
        }
    }
</style>

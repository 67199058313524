<template>
    <div class="order-section">
        <div class="action-btn__wrapper" v-if="isAdmin">
            <div v-for="button in regularOrderChangeStatusButtons" :key="button.id">
                <CRButton
                    v-if="checkOut.status === button.renderConditionStatus"
                    :class="button.class"
                    @click="openConfirmationModal(button.status)"
                    size="small"
                    :color="button.color"
                    :pattern="button.pattern"
                >
                    {{ button.title }}
                </CRButton>
            </div>


            <div v-if="!checkOut.deleted">
                <CRButton
                    v-if="checkOut.status === CheckOutStatusEnum.WAITING_PAYMENT"
                    :class="'mr-10'"
                    size="small"
                    :color="'danger'"
                    @click.prevent="softDelete"
                >
                   {{ $t('common.deleted')  }}
                </CRButton>
            </div>
            <div v-else>
                <CRButton
                    v-if="checkOut.status === CheckOutStatusEnum.WAITING_PAYMENT"
                    @click.prevent="softRestore"
                    :class="'mr-10'"
                    size="small"
                    :color="'danger'"
                    :loading="isLoading"
                >
                   {{ $t('common.restore')  }}
                </CRButton>
            </div>

            
        </div>

        <div class="card__wrapper">
            <RegularOrderKitCard
                class="card"
                v-for="order in checkOut.orders"
                :key="order.id"
                :order="order"
                :checkOut="checkOut"
                :kit-order-settings="kitOrderSettings"
                @updateOrder="updateOrder"
                @confirmReturnAmount="confirmReturnAmount"
                @reorderRegularKit="reorderRegularKit"
                @openChangeOrderModal="openChangeOrderModal"
            />
        </div>

        <ChangeOrderModal
            v-if="isChangeOrderModalOpen"
            :kit-order-settings="kitOrderSettings"
            :order="selectedOrder"
            :checkOut="checkOut"
            @close="closeChangeOrderModal"
        />

        <ConfirmationModal
            v-if="isConfirmationModalOpen"
            :title="confirmationModalTitle"
            :is-loading="isLoading"
            @close="closeConfirmationModal"
            @confirm="updateStatus"
        />

        <ConfirmationModal
            v-if="isConfirmationOrderModalOpen"
            :title="confirmationOrderModalTitle"
            :description="confirmationOrderModalDescription"
            :is-loading="isLoading"
            @close="closeOrderConfirmationModal"
            @confirm="confirmActionFromConfirmationModal"
        />

        <ConfirmationModal
            v-if="isConfirmationReturnAmountModalOpen"
            :title="$t('kitManagement.confirmReturnedAmountModalTitle')"
            :is-loading="isLoading"
            @close="closeConfirmationReturnAmountModal"
            @confirm="confirmMoneyBack"
        />

        <ConfirmationModal
            v-if="isConfirmationReturnAmountModalOpen"
            :title="$t('kitManagement.confirmReturnedAmountModalTitle')"
            :is-loading="isLoading"
            @close="closeConfirmationReturnAmountModal"
            @confirm="confirmMoneyBack"
        />
    </div>
</template>

<script>
    import RegularOrderKitCard from '@/components/Common/KitOrderCards/RegularOrderKitCard.vue';
    import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue';
    import ChangeOrderModal from '@/components/Modals/ChangeOrderModal.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { orderManagementSectionsMixin, kitManagementModalMixin, reorderROMixin } from '@/utils/mixins';
    import { CheckOutStatusEnum, OrderStatusEnum } from '@/utils/enums';
    import { regularOrderChangeStatusButtons } from '@/config/orderManagement/orderManagement';
    import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
    import { mapActions, mapGetters } from 'vuex';
    import { renderAllergenName } from '@/helpers/AllergenHelper';
    import { OrderApi, CheckOutApi } from '@/api';

    export default {
        name: 'RegularOrdersSection',
        components: { ConfirmationModal, CRButton, RegularOrderKitCard, ChangeOrderModal },
        mixins: [orderManagementSectionsMixin, kitManagementModalMixin, reorderROMixin],
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
            kitOrderSettings: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                getLabelForDeliveryTime,
                renderAllergenName,
                regularOrderChangeStatusButtons,
                CheckOutStatusEnum,
                isLoading: false,
                isConfirmationReturnAmountModalOpen: false,
                moneyBackStatus: '',
                selectedOrder: null,

                isChangeOrderModalOpen: false,
            };
        },

        computed: {
            ...mapGetters('auth', ['isAdmin']),
        },

        methods: {
            ...mapActions('orderManagement', ['getCheckOut']),

            confirmReturnAmount({ status, order }) {
                this.moneyBackStatus = status;
                this.selectedOrder = order;
                this.isConfirmationReturnAmountModalOpen = true;
            },

            async confirmMoneyBack() {
                this.isLoading = true;

                try {
                    await OrderApi.update(this.selectedOrder.id, {
                        moneyBackStatus: this.moneyBackStatus,
                    });

                    await this.getCheckOut(this.checkOut.id);
                } catch (error) {
                    console.log(error);
                } finally {
                    this.closeConfirmationReturnAmountModal();
                    this.isLoading = false;
                }
            },

            openChangeOrderModal(order) {
                this.selectedOrder = order;
                this.isChangeOrderModalOpen = true;
            },

            async closeChangeOrderModal() {
                try {
                    this.selectedOrder = null;
                    this.isChangeOrderModalOpen = false;

                    await this.getCheckOut(this.checkOut.id);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updateOrder({ status, order }) {
                let amount = '';
                if (status === OrderStatusEnum.CANCELED_BY_USER || status === OrderStatusEnum.CANCELED_BY_ADMIN) {
                    const response = await OrderApi.getOrderCancellationPrice(order.id);
                    amount = response.amount;
                }

                this.selectedOrder = order;
                this.openOrderConfirmationModal(status, amount);
            },

            async confirmActionFromConfirmationModal() {
                this.isLoading = true;

                try {
                    await OrderApi.update(this.selectedOrder.id, {
                        status: this.orderStatus,
                    });

                    await this.getCheckOut(this.checkOut.id);
                } catch (error) {
                    console.log(error);
                } finally {
                    this.closeOrderConfirmationModal();
                    this.isLoading = false;
                }
            },

            async softDelete() {
                try {
                    this.isLoading = true;
                    const response = await CheckOutApi.delete(this.checkOut.id);
                    await this.getCheckOut(this.checkOut.id);
                    if(response.status === 'error') {
                        this.$filters.toast(response.message, {
                            type: 'error',
                        });
                    } else {
                        this.$filters.toast(response.message, {
                            type: 'success',
                        });
                    }
                    this.isLoading = false;
                } catch (error) {
                    this.$filters.toast(error.message);
                    this.isLoading = false;
                }
            },

            async softRestore() {
                try {
                    this.isLoading = true;
                    const response = await CheckOutApi.restore(this.checkOut.id);
                    await this.getCheckOut(this.checkOut.id);
                    if(response.status === 'error') {
                        this.$filters.toast(response.message, {
                            type: 'error',
                        });
                    } else {
                        this.$filters.toast(response.message, {
                            type: 'success',
                        });
                    }
                    this.isLoading = false;
                } catch(error) {
                    this.$filters.toast(error.message);
                    this.isLoading = false;
                }
            },

            async updateStatus() {
                this.isLoading = true;

                try {
                    const payload = {
                        id: this.checkOut.id,
                        data: { status: this.status },
                    };

                    await this.updateCheckOut(payload);
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.closeConfirmationModal();
                    this.isLoading = false;
                }
            },

            closeConfirmationReturnAmountModal() {
                this.isConfirmationReturnAmountModalOpen = false;
                this.moneyBackStatus = '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .order-section {
        .action-btn__wrapper {
            @include row-align-start;

            width: 100%;
            margin-bottom: 40px;
        }

        .card__wrapper {
            width: 100%;
            @include row-align-start;

            flex-wrap: wrap;

            .card {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .header {
            h4 {
                cursor: pointer;
                margin-bottom: 12px;
                text-decoration: underline;
            }

            .tag__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                .tag {
                    margin-right: 8px;
                    margin-bottom: 10px;
                }
            }
        }

        .text-danger {
            font-size: $font-14;
            color: $danger;
            font-style: italic;
        }

        .payment-text__wrapper {
            max-width: 49%;
            margin-right: 1%;
        }
    }

    @include media($md) {
        .order-section {
            .card__wrapper {
                .card {
                    width: 49%;
                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($xl) {
        .order-section {
            .card__wrapper {
                .card {
                    width: 32%;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
